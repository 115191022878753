.login-container {
  width: 100% !important;

  margin-top: "20px";
  background: #ffffff;
  border-radius: 5%;
  text-align: center;
  padding: 20px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-1 {
  padding: 0 0 12px 0;
  font-size: 23px;
  font-weight: bolder;
  color: rgba(32, 49, 77, 1);
}
.centered {
  height: 100vh; /* Magic here */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sub-heading {
  font-size: 12px;
  color: #292922;
}

.login-container button {
  width: 50%;
  height: 40%;
  color: #ffffff;
  background-color: rgba(32, 49, 77, 1);
  padding: 12px;
  margin: 0 0 16px 0;
  border-radius: 10px;
  border: 0px;
}

.Logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18vw;
  height: auto;
  padding: 0;
  margin: 0;
  align-self: center;
}

img {
  /* text-align: center; */
  width: inherit;
  height: auto;
}

.main-login {
  background-image: url('../../assets/img/saksham_people.svg');
}

.flex-login {
  display: flex;
  flex-direction: row;
}

.flex-icon {
  margin-top: 20px;
  margin-right: 5px;
  height: 47px;
  background-color: #e8f0fe;
  padding: 10px;
  border: 1.5px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.center_content {
  display: flex;
  flex-direction: column;

  justify-content: center;
  height: 100%;
  align-content: center;
}

.input.input {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #e8f0fe;
  border: 1.5px solid #ffffff;
}
