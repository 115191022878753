.input {
  width: 100%;
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.2s all ease-in-out;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input:focus {
  border: 1.5px solid #292929;
  border-radius: 5px;
}

.invalid {
  border: 2px solid salmon !important;
}