.nav2-main {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav2-main__contents {
  display: flex;
  padding-top: 150px;
  height: 100%;
  width: calc(100vw - 11vw);
  padding: 1rem 1rem;
  justify-content: center;
  flex-direction: column;
}

.nav2-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
  width: 11vw;
  background-color: #20314d;
}

.lockdown-input {
  display: inline-block;
  width: 11vw;
  margin: 1em 0;
}

/* .hoverStyle {
  margin-bottom: 10%;
} */

@media only screen and (max-height: 600px) {
  .lockdown-input {
    margin: 1em 0;
  }

  .hoverStyle {
    margin-bottom: 0;
  }
}

.hoverStyle:hover {
  background-color: whitesmoke !important;
  color: #20314d !important;
}

.hoverStyle:focus {
  background-color: whitesmoke !important;
  color: #20314d !important;
}

#select-industry {
  color: whitesmoke !important;
}

.hoverStyle:hover #select-industry {
  color: #20314d !important;
}

.hoverStyle:hover #industry {
  background-color: whitesmoke;
  border: 2px solid #20314d;
  border-radius: 5px;
  color: #20314d;
}

.hoverStyle:hover #reco {
  background-color: whitesmoke;
  color: #20314d;
}

.button_active {
  border: 1px solid whitesmoke !important;
  box-shadow: 0 0 3pt 2pt whitesmoke !important;
  border-radius: 5px;
  outline: none !important;
}

.reset {
  height: auto;
  width: 50px;
  background-color: whitesmoke;
  color: #20314d;
  padding: 5px;
  margin: 5px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.typo-content {
  font-weight: bold !important;
  font-size: 0.9em !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: uppercase;
  text-align: start;
}

.hoverStyle:hover .icon_expand {
  fill: #20314d !important;
}

.icon_expand {
  fill: whitesmoke !important;
  padding: 0 !important;
  margin: 5px 0 5px -11px !important;
  width: 20px !important;
}

.social-media {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 11vw;
  height: max-content;
  /* margin-bottom: 4rem; */
}

.social-media_icon {
  width: 4vh;
  height: max-content;
  margin: 1%;
}
.email-icon {
  margin: 3%;
  align-self: center;
}
.email-id {
  color: whitesmoke;
  font-size: 0.8em;
  text-align: center;
  /* word-break: break-all; */
}

.left-align {
  text-align: left;
}
