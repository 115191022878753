* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
.main__vertical-bar {
  float: left;
}

.App {
  height: 100%;
  width: 100%;
}

.content {
  height: calc(100% - 120px);
}

body {
  background-color: white;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: #262626;
}

a:hover {
  text-decoration: none;
  color: #262626;
  opacity: 0.8;
}

p {
  margin: 0 !important;
}

button:disabled {
  cursor: not-allowed;
}

.mapboxgl-map {
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.MuiTypography-caption {
  /* color: darkslategrey; */
  font-size: 0.5rem;
}
