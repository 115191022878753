.table {
  margin-top: 30px;
  margin-left: 60px;
  display: block;
  width: 80vw;
  height: 400px;
}
thead {
  background-color: rgb(18, 18, 46);
  position: sticky;
  color: white;
  height: 30px;
}
th {
  width: 300px;
}
.t-body {
  border-bottom: 1px solid white;
  background-color: rgb(240, 243, 234);
  font-weight: 200;
}
tbody {
  height: 370px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
