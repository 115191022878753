/* @import url('https://fonts.googleapis.com/css?family=Raleway&display=swap'); */
* {
    box-sizing: border-box;
}

#root {
    position: absolute;
    height: 100%;
    width: 100%;
}

@font-face {
    font-family: 'Raleway';
    src: url('./assets/fonts/Raleway-Regular.ttf');
}

body {
    margin: 0;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    height: 100vh;
    background-color: #FAFAFA;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}