.main-container {
  display: flex;
}
.container {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  margin-right: 50px;
}
.kpi {
  height: 120px;
  width: 300px;
  margin-left: 50px;
  margin-top: 30px;
}
.kpi1 {
  background-color: bisque;
  height: 120px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-left: 50px;
  text-align: center;
  margin-top: 50px;
  font-size: 25px;
  font-weight: 600;
}
.company {
  margin-left: 50px;
}
.a {
  text-decoration: none;
  color: black;
}
/* .download {
  margin-top: 5px;
  margin-left: -2px;
  height: 35px;
  width: 35px;
} */
.Link {
  display: flex;
  align-items: center;
  font-size: larger;
  color: black;
  margin-left: 10px;
  width: 150px;
  margin-top: 10px;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid black;
}
.Link:hover {
  background-color: aqua;
}
.fb {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 5px;
}
.AllTable {
  height: 350px;
}
.paper {
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
