.city-search,
.ward-search {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* width: 100%; */
    /* align-content:space-between */
    margin-left:1.8%
    /* margin-left: 180px; */
}

.navbar-items-right {
    display: flex;
    position: absolute;
    right: 1em;
    align-items: center;
}

.navbar__selection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: auto 10px;
}

.navbar-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.navbar-label {
    letter-spacing: 0.2rem;
    text-align: center;
    /* margin-top: 5px; */
    background-color: black;
    font-size: smaller;
}

.navbar-navigation__button {
    display: inline-block;
    text-align: center;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    padding: 0.5rem;
}

.navbar-navigation__button:hover {
    border-bottom: 1px solid white;
}

.navbar-input-box {
    display: flex;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiInput-underline:after {
    border-bottom: 1px solid whitesmoke !important;
}

.user_profile {
    width: auto;
    height: auto;
    margin: 1%;
}

.flex-col-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* height: 10px; */
    /* margin-left: 5%; */
}

.pointer {
    cursor: pointer;
}

.ws-nowrap {
    font-size: 1.1vw;
    white-space: nowrap;
}

span.username {
    right: 10px;
    margin: 0 2px;
    text-align: center;
    min-width: 40px;
    width: auto;
}

.user_logo {
    width: 4vw !important;
    cursor: pointer !important;
}

.user_logo:hover {
    fill: lightgray;
}

.MuiPopover-paper {
    box-sizing: border-box;
    width: 12% !important;
    height: max-content !important;
    max-height: 20% !important;
    padding: 0 !important;
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.MuiMenuItem-root {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.navbar__remove {
    margin-left: 5px;
}

.navbar__remove:hover {
    cursor: pointer;
    font-weight: bolder;
}